import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconSolarPanel.module.scss';

const IconSolarPanel = ({ shouldStart = false, id = '' }) => {
    const SolarPanelAnimate = () => {
        anime({
            targets: `.${id}solarLine`,
            translateY: 0,
            translateX: 1,
            easing: 'linear',
            direction: 'alternate',
            duration: 300,
            loop: false,
        });
    };

    const ReverseSolarPanelAnimate = () => {
        anime({
            targets: `.${id}solarLine`,
            translateY: 2,
            translateX: -2,
            easing: 'linear',
            direction: 'alternate',
            duration: 300,
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? SolarPanelAnimate() : ReverseSolarPanelAnimate();
    });

    return (
        <div className={styles['IconSolarPanel']}>
            <svg
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m27.39 13.329 5.747 4.767"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                />
                <path
                    d="M27.012 31.035h-9.075a.368.368 0 0 0-.367.367v16.774h9.808V31.402a.368.368 0 0 0-.366-.367z"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeMiterlimit={10}
                />
                <path
                    d="M24.95 12.243a1 1 0 1 0 1.135-1.648l-1.135 1.648zm10.85 6.362a1 1 0 0 0-1.23 1.577l1.23-1.576zm-12.28-8.562-.568.823.567-.823zM7.398 20.627l.607.794-.607-.794zm.214 25.549v-23.96h-2v23.96h2zm.393-24.755 13.771-10.526-1.214-1.589-13.77 10.526 1.214 1.59zm14.946-10.555 1.998 1.377 1.135-1.648-1.999-1.376-1.134 1.647zM8.613 49.176H36.12v-2H8.613v2zm30.507-3V22.66h-2v23.515h2zm-1.154-25.88-2.165-1.69-1.231 1.576 2.166 1.69 1.23-1.575zm-1.846 28.88a3 3 0 0 0 3-3h-2a1 1 0 0 1-1 1v2zm-14.343-38.28a1 1 0 0 1 1.175-.03l1.134-1.647a3 3 0 0 0-3.523.087l1.214 1.59zM7.613 22.215a1 1 0 0 1 .393-.795l-1.215-1.589a3 3 0 0 0-1.178 2.384h2zm31.507.445a3 3 0 0 0-1.154-2.364l-1.23 1.576a1 1 0 0 1 .384.788h2zM5.613 46.176a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1h-2z"
                    fill="currentColor"
                />
                <path
                    className={id + 'solarLine'}
                    d="M37.75 11.863a3.698 3.698 0 0 0 3.698-3.699 3.698 3.698 0 0 0-3.699-3.698 3.698 3.698 0 0 0-3.698 3.698 3.698 3.698 0 0 0 3.698 3.699zm0-10.373V1m-5.02 2.152-.347-.347m-1.307 5.359h-.49m2.144 5.012-.347.348m5.367 1.314v.49m5.012-2.152.347.348m1.309-5.36h.49m-2.146-5.012.347-.347"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

IconSolarPanel.propTypes = {};

export default IconSolarPanel;
